.service-block:first-child {
    grid-column: 7 / 11;
    padding-top: 10vw;
    @media (max-width: 599px) {
        grid-column: 16 / 2;
        margin-top: 5vw;
    }
}
.service-block:nth-child(2) {
    grid-column: 2 / 6;
    margin-bottom: 10vw;
    @media (max-width: 599px) {
        grid-column: 16 / 2;
        margin-top: 5vw;
    }
}

.service-block:nth-child(3) {
    grid-column: 11 / 15;
    grid-row-start: 3;
    @media (max-width: 599px) {
        grid-column: 16 / 2;
        margin-top: 5vw;
    }
}


.service-block {
    position: relative;
    width: 100%;
}

.image {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
}

.service-block:first-child .image {
    position: absolute;
    left: -28vw;
    top: 0;
    @media (max-width: 599px) {
        top: 10vw;
        left: 0vw;

    }
}

.service-block:nth-child(2) .image {
    position: absolute;
    left: 61vw;
    top: -10vw;
    @media (max-width: 599px) {
        top: 5vw;
        left: 0vw;
    }
}

.service-block:nth-child(3) .image {
    position: absolute;
    left: -30vw;
    top: -7vw;
    @media (max-width: 599px) {
        top: 5vw;
        left: 0vw;

    }
}




