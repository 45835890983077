.landingExpertContainer, .landingExpertContainer .imgContainer, .landingExpertContainer .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.landingExpertContainer {
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color:  #d91a27;
}
.landingExpertContainer::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: #d91a27;
    z-index: -1;
    transform-origin: 0% 0%;
}

.landingExpertContainer .imgContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;

}
.landingExpertContainer img {
    width: 100vw;
    height: 100vh;
    transform: scale(1.35);
    object-fit: cover;
}
.landingExpertContainer .titleContainer {
    position: absolute;
    font-weight: 700;
    top: 50%;

    text-align: start;
    transform: translate(-50%, -50%);
    font-size: 9vw;
    z-index: 1;
    color: white;
    letter-spacing: 0.5rem;
    @media (max-width: 950px) {
        text-align: center;
        left: 50%;
    }
}
.landingExpertContainer .titleContainer span {
    display: block;
    opacity: 0;
    transform: translateY(100px);
}

@media (max-width: 1200px) {
    .landingExpertContainer .imgContainer {
        height: 100%;
        width: 100%;
        overflow: hidden;

    }
}
