.transition-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 800px;
        display: flex;
    }
    &.final {
        position: absolute;
        margin: 5vw auto;
        z-index: -100;
        @media (max-width: 480px) {
            top: -56px;
        }
        img {
            width: 100%;
            max-width: 100%;
            height: 90vh;
            object-fit: cover;
            object-position: center;
        }
        @media (max-width: 480px) {
            margin-top: 15vh;
        }
    }
}