.landing__work {
    position: relative;

    .service {
        flex-direction: column;
        align-items: center;
        @media (max-width: 1630px) {
            align-items: center;
        }
    }

    ._title {
        text-align: center;
        min-width: 350px;
        width: 40vw;
        margin: auto;
        margin-top: 100px;
        font-size: max(3vw, min(30px));
        font-weight: 400;
        letter-spacing: 0vw;
        line-height: 3vw;
    }
    .page-content {
        display: grid;
        grid-gap: 3rem;
        padding: 2rem;
        margin: 0 auto;

        @media (min-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
            padding: 4rem;
        }

        @media (min-width: 1350px) {
            grid-template-columns: repeat(3, 1fr);
            padding: 4rem;
        }
    }
    .card {
        position: relative;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        padding: 1rem;
        width: 100%;
        text-align: center;
        color: whitesmoke;
        background-color: whitesmoke;
        background-size: cover;
        background-position: center center;

        @media (min-width: 600px) {
            height: 350px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 110%;
            background-size: cover;
            background-position: 0 0;
            transition: transform calc(700ms * 1.5) cubic-bezier(0.19, 1, 0.22, 1);
            pointer-events: none;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200%;
            pointer-events: none;
            background-image: linear-gradient(
                    to bottom,
                    hsla(0, 0%, 0%, 0) 0%,
                    hsla(0, 0%, 0%, 0.009) 11.7%,
                    hsla(0, 0%, 0%, 0.034) 22.1%,
                    hsla(0, 0%, 0%, 0.072) 31.2%,
                    hsla(0, 0%, 0%, 0.123) 39.4%,
                    hsla(0, 0%, 0%, 0.182) 46.6%,
                    hsla(0, 0%, 0%, 0.249) 53.1%,
                    hsla(0, 0%, 0%, 0.320) 58.9%,
                    hsla(0, 0%, 0%, 0.394) 64.3%,
                    hsla(0, 0%, 0%, 0.468) 69.3%,
                    hsla(0, 0%, 0%, 0.540) 74.1%,
                    hsla(0, 0%, 0%, 0.607) 78.8%,
                    hsla(0, 0%, 0%, 0.668) 83.6%,
                    hsla(0, 0%, 0%, 0.721) 88.7%,
                    hsla(0, 0%, 0%, 0.762) 94.1%,
                    hsla(0, 0%, 0%, 0.790) 100%
            );
            transform: translateY(-50%);
            transition: transform calc(700ms * 2) cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 1rem;
        transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 1;

        > * + * {
            margin-top: 1rem;
        }
    }

    .title {
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.1em;
    }

    .copy {
        font-size: 1.125rem;
        line-height: 1.35;
        letter-spacing: 0.05em;
    }
    @media (hover: hover) and (min-width: 600px) {
        .card:after {
            transform: translateY(0);
        }

        .content {
            transform: translateY(calc(100% - 4.5rem));

            > *:not(.title) {
                opacity: 0;
                transform: translateY(1rem);
                transition:
                        transform 700ms cubic-bezier(0.19, 1, 0.22, 1),
                        opacity 700ms cubic-bezier(0.19, 1, 0.22, 1);
            }
        }

        .card:hover,
        .card:focus-within {
            align-items: center;

            &:before { transform: translateY(-4%); }
            &:after { transform: translateY(-50%); }

            .content {
                transform: translateY(0);

                > *:not(.title) {
                    opacity: 1;
                    transform: translateY(0);
                    transition-delay: calc(700ms / 8);
                }
            }
        }

        .card:focus-within {
            &:before,
            &:after,
            .content,
            .content > *:not(.title) {
                transition-duration: 0s;
            }
        }
    }
}