footer {
    background-color: #121212;
    padding: 0 2rem 0;
    font-size: 1rem;
    line-height: 20px;
    color: #ffffff;
    font-weight: 500;
    padding-bottom: 100px;
    width: 100%;
}

.about {
    width: 50%;
    text-align: left;
    margin-left: 50px;
    padding: 1rem;
    color: #737373;
    @media (max-width: 599px) {
        margin-left: 0px;
    }
    li{
        margin-top: 10px;
        list-style-type: none;
    }
    a{
        color: white;
        text-decoration: none;
    }
    a:hover{
        color: #d91a27;
    }
}

.branch {
    margin-left: 50px;
    padding: 1rem;
    border-bottom: 1px solid #737373;
    @media (max-width: 5000px) {
        margin-left: 0px;
    }
}

.brancH2 {
    margin: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;

}

.branch_list {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.branch_list span {
    margin: 0.5rem;
}

.lower {
    display: flex;
}

.branch_list span:active,
.branch_list span:focus,
.branch_list span:hover {
    color: #d91a27;
    text-decoration: none;
}

.networks {
    text-align: center;
    padding: 1rem;
    width: 100%;
    color: #737373;
}

.networks_container {
    padding: 25px 50px;
}
.networks_a {
    margin: 1rem;
    transition: transform 250ms;
    display: inline-block;
    color: white;
}

.networks_a:hover {
    color: #d91a27 !important;
}

.link_a {
    transition: transform 250ms;
    display: inline-block;
    color: white;
}

.link_a:hover {
    color: #d91a27 !important;
}

.p_footer {
    text-align: start;
    color: #737373;
}

.p_footer_politique {
    margin-left: 50px;
}


