.slideC {
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    width: 400px;
    height: 42vw;
    max-height: 300px;
    margin: 0 auto;
}

.slide {
    width: 400px;
    height: 40vw;
    max-height: 250px;
    transition: transform 500ms ease 0s, opacity 500ms ease 0s,
    visibility 500ms ease 0s;
    position: absolute;
    top: 0;
    background-color: white;
    border: 1px solid #e6e6e6;
    font-size: max(1vw, min(16px));
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sliderContent {
    display: flex;
    flex-direction: column;
    color: black;
    padding: 30px;
    align-items: flex-start;
}

.sliderContent h2 {
    margin: 16px 0;
}
.sliderContent p {
    margin: 0;
    margin-bottom: 16px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.btns {
    padding-top: 50px;
    margin-right: 50px;
    text-align: center;
}

.btns .btn:last-child {
    margin-left: 50px;
}

