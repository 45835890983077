@font-face {
  src: url('../public/fonts/Decker/Decker.ttf');
  font-family:'Decker';
  font-style: normal;
}
@font-face {
  src: url('../public/fonts/Decker/DeckerB.ttf');
  font-family:'DeckerB';
  font-style: normal;
  font-weight: bold;
}

html {
  --cursor-color: #555;
}

body{
  color: rgba(0, 0, 0, 100%) !important;
}

.app {
  font-family: 'Decker', sans-serif;
  line-height: 1.5;
  scroll-behavior: smooth;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: top;
}

.map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

