.testimonial-container {
    margin: 15px 150px;
    padding-bottom: 0;
    text-align: center;
    @media (max-width: 1100px) {
            margin: 15px 30px;
    }
    @media (max-width: 800px) {
            margin: 15px 25px;
    }
}

.team {
    flex-direction: column;
    align-items: center;
}

.team__desc {
    text-align: center;
    font-size: 1rem;
    max-width: 600px;
    @media (max-width: 600px) {
            text-align: center;
            margin-top: 2em;
    }
}

.testimonials-description {
    color: #555;
    text-align: center;
    margin: 10px 0;
    padding: 0 30px;
}


.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    display: none !important;
}

li.alice-carousel__dots-item.__active {
    background-color: #060606 !important;
}

ul.alice-carousel__dots {
    padding: 8px;
    margin: 0;
}

.section__title {
    text-align: center;
    font-size: max(3vw, min(30px));
    font-weight: 400;
    letter-spacing: 0vw;
    line-height: 3vw;
    width: 40vw;
    margin: auto;
}

