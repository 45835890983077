.banner {
    z-index: 1;
    position: relative;
    margin-top: 30vh;
    @media (max-width: 480px) {
        margin-top: 30vh;
    }
    .banner-row {
        overflow: hidden;
        display: flex;
        align-items: center;
        &.center {
            justify-content: center;
            text-align: center;
            .row-letter {
                left: 0;
            }
        }
        &.right {
            justify-content: flex-end;
        }
        .row-title,
        .row-letter {
            font-size: 9vw;
            font-weight: 700;
            position: relative;
            letter-spacing: 0.5rem;
            color: white;
        }
        .row-col {
            width: 50%;
            display: flex;
            align-content: center;
            justify-content: center;
            .row-message {
                font-weight: 700;
                font-size: 9vw;
                line-height: 2.6rem;
                width: 320px;
            }
        }
    }
}

